import React, { useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PencilIcon, TrashIcon } from '@heroicons/react-v2/24/outline';
import { PlusIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  GetWebinarDataQuery,
  useWebinarAskQuestionMutation,
} from '../../../apollo/generated';
import {
  Typography,
  TextArea,
  Button,
  useWebinarEditQuestionMutation,
  useWebinarDeleteQuestionMutation,
} from '../../../index';
import { useAlert } from '../../context/alert-context';
import { setReturnToCookie } from '../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const WebinarQuestions: React.FC<{
  client?: ApolloClient<NormalizedCacheObject>;
  hasStarted: boolean;
  isLoggedIn?: boolean;
  questions?:
    | NonNullable<
        NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
      >['questions']
    | null;
  webinar: NonNullable<
    NonNullable<GetWebinarDataQuery['webinarData']>['webinar']
  >;
}> = ({ client, hasStarted, isLoggedIn, questions, webinar }) => {
  const { asPath } = useRouter();
  const [askQuestion] = useWebinarAskQuestionMutation({
    client, // Add client here
    refetchQueries: ['GetWebinarData'],
  });
  const [askAnother, setAskAnother] = useState(false);
  const { formatAndShowError, showAlert } = useAlert();

  interface FormData {
    content: string;
  }

  const { control, formState, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { content: '' },
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object().shape({
        content: yup.string().trim().required('Question cannot be blank.'),
      })
    ),
  });

  const onSubmitAsk = async (data: FormData) => {
    if (hasStarted)
      return showAlert({
        description: 'Webinar has already started',
        variant: 'error',
      });
    await askQuestion({
      variables: { content: data.content, webinarId: webinar.id },
    })
      .then(() => {
        setAskAnother(false);
        reset();
        showAlert({
          description: 'Question submitted successfully',
          variant: 'success',
        });
      })
      .catch(formatAndShowError);
  };

  const renderForm = () => (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmitAsk)}>
      <Controller
        control={control}
        name="content"
        render={({ field, fieldState: { error } }) => (
          <TextArea
            {...field}
            error={!!error}
            helperText={error?.message}
            id="webinar-question"
            placeholder="Type your question here..."
          />
        )}
      />

      <Button
        color="black"
        disabled={formState.isSubmitting}
        type="submit"
        variant="primary"
      >
        Submit
      </Button>
    </form>
  );

  const renderContentTitleAndDescription = () => {
    if (hasStarted) {
      return (
        <div className="flex flex-col gap-8">
          <Typography variant="heading-2">Questions disabled</Typography>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-8">
        <Typography variant="heading-2">
          Have a question for this webinar?
        </Typography>
        <Typography>
          Pre-submit your question below for a chance to have it answered during
          the webinar.
        </Typography>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col gap-6">
        {renderContentTitleAndDescription()}
        {questions?.length ? (
          <>
            {questions.map((question) => {
              if (!question) return null;
              return (
                <WebinarQuestion
                  key={`webinar-question-${question?.id}`}
                  client={client}
                  hasStarted={hasStarted}
                  question={question}
                  setAskAnother={setAskAnother}
                />
              );
            })}
            {!hasStarted &&
              (askAnother ? (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-6 md:flex-row md:justify-between">
                    <Typography variant="body-large">
                      Ask another question
                    </Typography>
                    <div className="flex shrink-0 items-center justify-end gap-2">
                      <div
                        className="flex cursor-pointer items-center gap-1 opacity-80 hover:opacity-90"
                        onClick={() => setAskAnother(false)}
                      >
                        <XIcon className="h-4 w-4" />
                        <Typography variant="body-small">Cancel</Typography>
                      </div>
                    </div>
                  </div>
                  {renderForm()}
                </div>
              ) : (
                <div className="flex justify-end">
                  <Button
                    color="white"
                    size="sm"
                    startIcon={<PlusIcon className="h-3 w-3" />}
                    variant="primary"
                    onClick={() => setAskAnother(true)}
                  >
                    Ask another
                  </Button>
                </div>
              ))}
          </>
        ) : isLoggedIn && !hasStarted ? (
          renderForm()
        ) : (
          <>
            <Typography>
              You&apos;ll need to sign up or log in to submit a question.
            </Typography>
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <Link href="/auth/signup">
                <Button
                  variant="primary"
                  onClick={() => setReturnToCookie(asPath)}
                >
                  Sign up
                </Button>
              </Link>
              <span>or</span>
              <Link href="/auth/signin">
                <Button
                  variant="primary"
                  onClick={() => setReturnToCookie(asPath)}
                >
                  Log in
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    );
  };

  if (hasStarted && !questions?.length) return null;

  return (
    <div className="w-full bg-company-primary text-company-primary-text">
      <div className="mx-auto w-full max-w-screen-xl px-4 py-8 sm:px-6 sm:py-16">
        <div className="w-full max-w-[576px]">{renderContent()}</div>
      </div>
    </div>
  );
};

const WebinarQuestion: React.FC<{
  client?: ApolloClient<NormalizedCacheObject>;
  hasStarted: boolean;
  question: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<GetWebinarDataQuery['webinarData']>['attendee']
      >['questions']
    >['0']
  >;
  setAskAnother: React.Dispatch<React.SetStateAction<boolean>>; // Add client to props
}> = ({ client, hasStarted, question, setAskAnother }) => {
  const [editQuestion] = useWebinarEditQuestionMutation({
    client, // Add client here
    refetchQueries: ['GetWebinarData'],
  });
  const [deleteQuestion] = useWebinarDeleteQuestionMutation({
    client, // Add client here
    refetchQueries: ['GetWebinarData'],
  });
  const { formatAndShowError, showAlert } = useAlert();
  const [isEditing, setIsEditing] = useState(false);

  interface FormData {
    content: string;
  }

  const onSubmitEdit = async (data: FormData) => {
    if (hasStarted)
      return showAlert({
        description: 'Webinar has already started',
        variant: 'error',
      });
    await editQuestion({
      variables: { content: data.content, questionId: question.id },
    })
      .then(() => {
        showAlert({
          description: 'Question edited successfully',
          variant: 'success',
        });
        setIsEditing(false);
        setAskAnother(false);
      })
      .catch(formatAndShowError);
  };

  const handleDelete = async () => {
    if (hasStarted)
      return showAlert({
        description: 'Webinar has already started',
        variant: 'error',
      });
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this question?'
    );
    if (!confirmDelete) {
      return;
    } else {
      await deleteQuestion({
        variables: { questionId: question.id },
      })
        .then(() => {
          showAlert({
            description: 'Question deleted successfully',
            variant: 'success',
          });
          setIsEditing(false);
          setAskAnother(false);
        })
        .catch(formatAndShowError);
    }
  };

  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: { content: question.content },
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object().shape({
        content: yup.string().trim().required('Question cannot be blank.'),
      })
    ),
  });

  return isEditing ? (
    <div>
      <Typography variant="heading-2">
        We&apos;ve received your question
      </Typography>
      <Typography>Edit your question: </Typography>
      <form className="space-y-8" onSubmit={handleSubmit(onSubmitEdit)}>
        <Controller
          control={control}
          name="content"
          render={({ field, fieldState: { error } }) => (
            <TextArea
              {...field}
              error={!!error}
              helperText={error?.message}
              id="webinar-question"
              placeholder="Type your question here..."
            />
          )}
        />

        <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
          <Button
            color="black"
            disabled={formState.isSubmitting}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>

          <Button
            disabled={formState.isSubmitting}
            type="button"
            variant="tertiary"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <div className="flex flex-col items-end gap-2">
      <div className="flex w-full flex-col gap-2">
        <div className="flex items-center justify-between gap-2">
          <Typography className="opacity-80" variant="body-small">
            {'Asked '}
            {dayjs(question.updatedAt).format('DD MMM YYYY')}
            {' at '}
            {dayjs(question.updatedAt).format('h:mm A')}
          </Typography>
          {!hasStarted ? (
            <div className="flex items-center gap-3">
              <div className="flex shrink-0 items-center justify-end gap-2">
                <div
                  className="flex cursor-pointer items-center gap-1 opacity-80 hover:opacity-90"
                  onClick={() => setIsEditing(true)}
                >
                  <PencilIcon className="h-4 w-4" />
                  <Typography variant="body-small">Edit</Typography>
                </div>
              </div>

              <div className="flex shrink-0 items-center justify-end gap-2">
                <div
                  className="flex cursor-pointer items-center gap-1 opacity-80 hover:opacity-90"
                  onClick={() => handleDelete()}
                >
                  <TrashIcon className="h-4 w-4" />
                  <Typography variant="body-small">Delete</Typography>
                </div>
              </div>
            </div>
          ) : (
            <span />
          )}
        </div>
        <div className="relative w-full rounded-lg border px-2 py-3">
          <Typography className="whitespace-pre-line">
            {question.content}
          </Typography>
        </div>
      </div>
    </div>
  );
};
