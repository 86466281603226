import { type Block } from '@blocknote/core';
import { ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { getCldOgImageUrl } from 'next-cloudinary';
import Image from 'next/image';
import Link from 'next/link';
import {
  LinkButton,
  Typography,
  WebinarsQuery,
  RenderBlockNote,
} from '../../../index';
import CloudinaryImage from '../../ui/cloudinary-image';
import { getLocalDate } from '../../webinars/webinar-util';

interface Props {
  logoUrl: string;
  webinar: NonNullable<
    NonNullable<
      NonNullable<NonNullable<WebinarsQuery['webinars']>['edges']>[0]
    >['node']
  >;
}

export const WebinarBlockCard: React.ComponentType<Props> = ({
  logoUrl,
  webinar,
}) => {
  const url = `/webinars/${webinar.slug}`;
  const formatWebinarTime = (
    date: string | null | undefined,
    timezone: string | null | undefined,
    format: string
  ) => {
    return getLocalDate(date, timezone).format(format);
  };

  const renderCompanyIcon = () => {
    if (!logoUrl) {
      return null;
    }
    return (
      <div className="absolute left-[50%] top-[50%] flex h-[64px] w-[64px] -translate-x-1/2 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full bg-transparent fill-gray-200 ring-inset ring-gray-200">
        <CloudinaryImage
          alt="Logo"
          className="h-full w-full object-contain"
          height={250}
          src={logoUrl}
          width={250}
        />
      </div>
    );
  };

  return (
    <Link
      className={clsx(
        'group flex flex-col overflow-hidden rounded-lg border bg-hubs-background shadow-none transition-shadow md:hover:shadow-md'
      )}
      href={url}
    >
      <div
        className={clsx(
          'flex w-full shrink-0 items-center justify-center overflow-hidden bg-gray-100 ring-1 ring-inset ring-gray-200'
        )}
      >
        <div className={clsx('relative h-full w-full pt-[56.25%]')}>
          {webinar.imageCloudinaryId ? (
            <Image
              alt={webinar.title || 'webinar-image'}
              className="absolute left-0 top-0 aspect-video h-full w-full"
              height={360}
              src={getCldOgImageUrl({
                src: webinar.imageCloudinaryId || '',
              })}
              style={{ objectFit: 'cover' }}
              width={640}
            />
          ) : (
            renderCompanyIcon()
          )}
        </div>
      </div>
      <div
        className={clsx(
          'flex h-full w-full flex-col justify-between p-4 md:p-6'
        )}
      >
        <div>
          <Typography className="mb-2 text-hubs-primary" variant="button">
            {webinar.title}
          </Typography>
          <Typography
            className="mb-4 text-hubs-secondary md:mb-2"
            variant="fine-print"
          >
            {formatWebinarTime(
              webinar.startTime,
              webinar.timezone,
              'DD/MM/YYYY'
            )}
          </Typography>
          {webinar.summary ? (
            <div className="mb-2 line-clamp-2 max-h-12 overflow-hidden font-body text-hubs-secondary">
              <RenderBlockNote
                plaintext
                content={(webinar.summary as { content: Block[] }).content}
              />
            </div>
          ) : null}
        </div>
        <LinkButton className="mt-4 flex items-center gap-2 font-body text-sm !text-company-primary !no-underline">
          View webinar
          <ArrowRightIcon className="size-3.5 transition-transform group-hover:translate-x-1/4" />
        </LinkButton>
      </div>
    </Link>
  );
};
