'use client'; // https://www.blocknotejs.org/docs/advanced/nextjs - can't use blocknote in server side
import React, { useEffect, useState } from 'react';
import { Block } from '@blocknote/core';
import { useCreateBlockNote } from '@blocknote/react';
import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import {
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  FacebookIcon,
} from '../../icons';
import Typography from '../../typography';
import { DUMMY_LOGO_URL } from '../constants';
import { ImageMeta } from '../types';
import CloudinaryImage from '../ui/cloudinary-image';

export interface FooterNewsletterSubscription {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description?: any;
  heading?: string | null;
  isSimpleForm?: boolean;
}

export interface FooterBadge {
  image?: ImageMeta | null;
  postion: number;
  url?: string | null;
}

export interface FooterSiteMapLinkGroupItem {
  label: string;
  position: number;
  url: string;
}

export interface FooterSiteMapLinkGroup {
  label: string;
  links?: FooterSiteMapLinkGroupItem[];
  position: number;
}

export interface FooterDescription {
  content: Block[];
}

export interface FooterProps {
  address?: string | null;
  badges?: FooterBadge[];
  customFooterLinks?: React.ReactNode;
  customNewsletterSubscriptionPromptContent?: React.ReactNode;
  description?: FooterDescription;
  email?: string | null;
  facebookUrl?: string | null;
  footerBackgroundColor?: string | null;
  footerButtonBackgroundColor?: string | null;
  footerButtonTextColor?: string | null;
  footerLogoCloudinaryUrl?: string | null;
  footerTextColor?: string | null;
  hubBuilderUrl?: string;
  instagramUrl?: string | null;
  isNewsletterSubscriptionEnabled?: boolean | null;
  isQmodEnabled?: boolean;
  isSitemapEnabled?: boolean | null;
  isUK?: boolean;
  linkedinUrl?: string | null;
  marketListingKey: string;
  newsletterSubscription?: FooterNewsletterSubscription;
  newsletterSubscriptionPrompt: React.ReactNode;
  sitemapLinkGroups?: FooterSiteMapLinkGroup[];
  telephone?: string | null;
  twitterUrl?: string | null;
}

export const Footer: React.ComponentType<FooterProps> = ({
  address,
  badges,
  customFooterLinks,
  customNewsletterSubscriptionPromptContent,
  description,
  email,
  facebookUrl,
  footerBackgroundColor,
  footerButtonBackgroundColor,
  footerButtonTextColor,
  footerLogoCloudinaryUrl,
  footerTextColor,
  hubBuilderUrl,
  instagramUrl,
  isNewsletterSubscriptionEnabled,
  isQmodEnabled,
  isSitemapEnabled,
  isUK,
  linkedinUrl,
  marketListingKey,
  newsletterSubscription,
  newsletterSubscriptionPrompt,
  sitemapLinkGroups,
  telephone,
  twitterUrl,
}) => {
  const [descriptionHTML, setDescriptionHTML] = useState<string>('');
  const [
    newsletterSubscriptionDescriptionHTML,
    setNewsletterSubscriptionDescriptionHTML,
  ] = useState<string>('');

  const editor = useCreateBlockNote();

  const getUrl = (url: string) => {
    if (url === '/') {
      return hubBuilderUrl ? hubBuilderUrl + '/home' : '/';
    }

    if (url.startsWith('/')) {
      return (hubBuilderUrl ?? '') + url;
    }

    return url;
  };

  useEffect(() => {
    const convertNewsletterSubscriptionToHTML = async () => {
      if (newsletterSubscription?.description) {
        const html = await editor.blocksToHTMLLossy(
          newsletterSubscription.description
        );
        if (html === '<p></p>') {
          setNewsletterSubscriptionDescriptionHTML('');
        } else {
          setNewsletterSubscriptionDescriptionHTML(html);
        }
      }
    };

    convertNewsletterSubscriptionToHTML();
  }, [editor, newsletterSubscription?.description]);

  useEffect(() => {
    const convertDescriptionToHTML = async () => {
      if (description?.content) {
        const html = await editor.blocksToHTMLLossy(description.content);
        if (html === '<p></p>') {
          setDescriptionHTML('');
        } else {
          setDescriptionHTML(html);
        }
      }
    };

    convertDescriptionToHTML();
  }, [editor, description]);

  const getImageAspectRatioCss = (
    height?: number | null,
    width?: number | null
  ) => {
    if (height && width) {
      return `aspect-[${width}/${height}]`;
    }

    return 'aspect-square';
  };

  const getImageDimension = (height?: number | null, width?: number | null) => {
    if (height) {
      if (height > 80) {
        const defaultWidth = width ?? 80;
        return {
          height: 80,
          width: (80 * defaultWidth) / height,
        };
      }
    }

    return { height: 80, width: 80 };
  };

  const hasDescription = !!descriptionHTML;
  const hasContacts = !!(address || telephone || email);
  const hasSocials = !!(
    twitterUrl ||
    linkedinUrl ||
    facebookUrl ||
    instagramUrl
  );
  const hasBadges = badges && badges.length > 0;
  const hasLogo = footerLogoCloudinaryUrl && footerLogoCloudinaryUrl !== 'none';
  const hasSection1 = hasDescription || hasSocials || hasContacts;

  return (
    <footer
      className={clsx(
        'w-full bg-footer-background-color px-4 py-16 text-footer-text-color sm:px-20'
      )}
    >
      <div className="mx-auto max-w-screen-xl space-y-8 overflow-hidden">
        {(hasSection1 || hasBadges || hasLogo) && (
          <div className="space-y-8">
            {footerLogoCloudinaryUrl ? (
              footerLogoCloudinaryUrl === 'none' ? null : (
                <div className="relative z-0 h-[100px] w-full">
                  <Image
                    priority
                    alt="logo"
                    className="z-10 h-[100px] w-auto
                "
                    height="0"
                    quality={100}
                    sizes="100vw"
                    src={footerLogoCloudinaryUrl}
                    width="0"
                  />
                </div>
              )
            ) : (
              <Image
                alt="Dummy logo"
                height={46}
                src={DUMMY_LOGO_URL}
                width={46}
              />
            )}
            {(hasSection1 || hasBadges) && (
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-32">
                {hasSection1 && (
                  <div className="col-span-1 space-y-8">
                    {hasDescription && (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                          className="blocknote prose max-w-none list-inside list-disc font-body text-sm leading-normal text-footer-text-color marker:text-footer-text-color prose-headings:text-footer-text-color prose-p:text-footer-text-color prose-a:text-footer-text-color prose-strong:text-footer-text-color prose-table:text-footer-text-color"
                        />
                      </div>
                    )}
                    {hasContacts && (
                      <div className="space-y-4">
                        {address && (
                          <div className="flex items-center gap-3">
                            <div className="h-5 w-5">
                              <MapPinIcon className="h-5 w-5" />
                            </div>
                            <Typography variant="body-small">
                              {address}
                            </Typography>
                          </div>
                        )}
                        {telephone && (
                          <div className="flex items-center gap-3">
                            <div className="h-5 w-5">
                              <PhoneIcon className="h-5 w-5" />
                            </div>
                            <Typography variant="body-small">
                              {telephone}
                            </Typography>
                          </div>
                        )}
                        {email && (
                          <div className="flex items-center gap-3">
                            <div className="h-5 w-5">
                              <EnvelopeIcon className="h-5 w-5" />
                            </div>
                            <Typography variant="body-small">
                              <a href={`mailto:${email}`}>{email}</a>
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                    {hasSocials && (
                      <div className="flex items-center gap-8">
                        {twitterUrl && (
                          <a
                            href={twitterUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="h-5 w-5">
                              <TwitterIcon className="h-5 w-5" />
                            </div>
                          </a>
                        )}
                        {linkedinUrl && (
                          <a
                            href={linkedinUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="h-5 w-5">
                              <LinkedinIcon className="h-5 w-5" />
                            </div>
                          </a>
                        )}
                        {facebookUrl && (
                          <a
                            href={facebookUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="h-5 w-5">
                              <FacebookIcon className="h-5 w-5" />
                            </div>
                          </a>
                        )}
                        {instagramUrl && (
                          <a
                            href={instagramUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className="h-5 w-5">
                              <InstagramIcon className="h-5 w-5" />
                            </div>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {hasBadges && (
                  <div className="col-span-1 flex flex-wrap justify-end gap-8">
                    {badges
                      ?.slice()
                      .sort((a, b) => a.postion - b.postion)
                      .map((badge, badgeIndex) => (
                        <Link
                          key={`footer-badge-${badgeIndex}`}
                          href={getUrl(
                            badge.url && badge.url.length
                              ? badge.url
                              : badge.image?.cloudinaryUrl ?? '/'
                          )}
                          target="_blank"
                        >
                          <CloudinaryImage
                            alt={
                              badge.image?.originalFilename ||
                              'Background image'
                            }
                            className={clsx(
                              'max-h-20',
                              getImageAspectRatioCss(
                                badge.image?.height,
                                badge.image?.width
                              ),
                              badge.image?.height && badge.image.height > 80
                                ? 'object-contain'
                                : 'object-cover'
                            )}
                            height={
                              getImageDimension(
                                badge.image?.height,
                                badge.image?.width
                              ).height
                            }
                            src={badge.image?.cloudinaryUrl || ''}
                            width={
                              getImageDimension(
                                badge.image?.height,
                                badge.image?.width
                              ).width
                            }
                          />
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {isSitemapEnabled &&
          sitemapLinkGroups &&
          sitemapLinkGroups.length > 0 && (
            <div className="grid grid-cols-1 gap-8 border-t border-footer-text-color pt-8 md:grid-cols-5 md:gap-14">
              {sitemapLinkGroups &&
                sitemapLinkGroups
                  .slice()
                  .sort(
                    (linkGroup1, linkGroup2) =>
                      linkGroup1.position - linkGroup2.position
                  )
                  .map((linkGroup, linkGroupIndex) => (
                    <div
                      key={`sitemap-link-group-${linkGroupIndex}`}
                      className="space-y-4"
                    >
                      <Typography
                        className="whitespace-pre-wrap break-words"
                        variant="subtitle-2"
                      >
                        {linkGroup.label}
                      </Typography>
                      {linkGroup.links &&
                        linkGroup.links
                          .slice()
                          .sort(
                            (link1, link2) => link1.position - link2.position
                          )
                          .map((link, linkIndex) => (
                            <div
                              key={`sitemap-link-group-${linkGroupIndex}-item-${linkIndex}`}
                            >
                              <Link
                                className="hover:opacity-90"
                                href={getUrl(link?.url ?? '')}
                                target={
                                  link?.url?.startsWith('/')
                                    ? '_self'
                                    : '_blank'
                                }
                              >
                                <Typography
                                  className="whitespace-pre-wrap break-words"
                                  variant="body-small"
                                >
                                  {link.label}
                                </Typography>
                              </Link>
                            </div>
                          ))}
                    </div>
                  ))}
            </div>
          )}

        {isNewsletterSubscriptionEnabled && (
          <>
            <div
              className={clsx(
                'grid grid-cols-1 gap-8 border-t border-footer-text-color pt-8',
                newsletterSubscription?.isSimpleForm
                  ? 'md:grid-cols-2 md:gap-32'
                  : 'md:gap-4'
              )}
            >
              {(newsletterSubscription?.heading ||
                newsletterSubscriptionDescriptionHTML) && (
                <div className="col-span-1 max-w-[544px] space-y-1">
                  {newsletterSubscription?.heading && (
                    <Typography variant="subtitle-2">
                      {newsletterSubscription.heading}
                    </Typography>
                  )}
                  {newsletterSubscriptionDescriptionHTML && (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: newsletterSubscriptionDescriptionHTML,
                        }}
                        className="blocknote prose max-w-none list-inside list-disc font-body text-sm leading-normal text-footer-text-color marker:text-footer-text-color prose-headings:text-footer-text-color prose-p:text-footer-text-color prose-a:text-footer-text-color prose-strong:text-footer-text-color prose-table:text-footer-text-color"
                      />
                    </div>
                  )}
                </div>
              )}

              <div
                className={clsx(
                  'inline-flex w-full space-y-1',
                  newsletterSubscription?.heading ||
                    newsletterSubscriptionDescriptionHTML
                    ? 'col-span-1'
                    : 'col-span-2 justify-center'
                )}
              >
                {newsletterSubscriptionPrompt}
              </div>
            </div>
            {customNewsletterSubscriptionPromptContent}
          </>
        )}
        <div className="space-y-4">
          <div
            className={clsx(
              'flex flex-wrap items-center justify-center gap-4  border-footer-text-color',
              hasSection1 || hasBadges || hasLogo ? 'border-t pt-8' : ''
            )}
          >
            {customFooterLinks}
          </div>
          {isQmodEnabled && (
            <div className="flex items-center justify-center pt-4">
              <Typography
                className="block text-center sm:hidden"
                variant="body-small"
              >
                TSXV Financial Market Data powered by{' '}
                <Link
                  className="font-semibold"
                  href="https://www.quotemedia.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Quotemedia.com
                </Link>
                All rights reserved Data delayed 15 minutes unless indicated. •{' '}
                <Link
                  className="font-semibold"
                  href="https://www.quotemedia.com/legal/tos"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View the Terms of Use
                </Link>
              </Typography>

              <Typography
                className="hidden text-center sm:block"
                variant="body-small"
              >
                TSXV Financial Market Data powered by{' '}
                <Link
                  className="font-semibold"
                  href="https://www.quotemedia.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Quotemedia.com
                </Link>
                . All rights reserved. Data delayed 15 minutes unless indicated.{' '}
                {' • '}
                <Link
                  className="font-semibold"
                  href="https://www.quotemedia.com/legal/tos"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View the Terms of Use
                </Link>
              </Typography>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
